import { FormGroup, Tooltip } from 'reactstrap'
import Select, { StylesConfig } from 'react-select';
import { projectStatusObject } from '../../utils/constants'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
//@ts-ignore
import { StickyTable, Row, Cell } from 'react-sticky-table';
import SearchInput from '../DesignSystem/Core/Common/SearchInput/SearchInput'
import SingleDatePickerComponent from '../SingleDatePickerComponent'
import moment from 'moment'
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import axios from 'axios'
import { addImage } from '../../api'
import QButton from '../DesignSystem/Core/Common/QButton/QButton'
import '@fortawesome/fontawesome-free/css/all.min.css'
import { BuyCurrencyEnum, OrderStatus, PaymentMethodEnum } from '../../utils/types';
import { camelCaseToSpaced } from '../../utils/common';

export const Dummy = () => {
  return 'Dummy'
}

export const ProjectStatusOptionList = () => (
  <>
    <option value={projectStatusObject.green.key}>
      {projectStatusObject.green.label}
    </option>
    <option value={projectStatusObject.yellow.key}>
      {projectStatusObject.yellow.label}
    </option>
    <option value={projectStatusObject.red.key}>
      {projectStatusObject.red.label}
    </option>
  </>
)
export const IsProjectCompleteOptionsComponent = () => (
  <>
    <option value={'false'}>Not Completed</option>
    <option value={'true'}>Completed</option>
  </>
)

export const ColorBar = ({ color }: { color: string }) => (
  <span className={'color-bar ' + color}></span>
)

export const ChevronUpDown = ({
  sortDirection,
  thisSortBy,
  sortBy,
}: {
  sortDirection: string
  thisSortBy: string
  sortBy: string
}) => {
  return (
    <i
      className={`ml-1 ti-angle-${sortDirection === 'asc' ? 'down' : 'up'} ${sortBy === thisSortBy ? 'activeSort' : ''}`}
    ></i>
  )
}

export const SortableTableHeader = ({
  label,
  property,
  sortBy,
  sortDirection,
  handleSortTableHead,
}: any) => (
  <Cell>
    <span className="sortable" onClick={() => handleSortTableHead(property)}>
      {label}
      <ChevronUpDown
        sortDirection={sortDirection}
        thisSortBy={property}
        sortBy={sortBy}
      />
    </span>
  </Cell>
)

export const TableTopFilters = ({
  state,
  setState,
  getData,
  showDateRange = true,
  handleExportOrders,
}: any) => (
  <div className="row mb-2">
    <div className="col-lg-12">
      <FormGroup className="search-by-wrap">
        <select
          className="form-control"
          value={state.searchType}
          onChange={(e) => {
            setState({ ...state, searchType: e.target.value })
          }}
        >
          <option value="name">Search User by Name</option>
          <option value="email">Search User by Email</option>
        </select>
        <SearchInput
          onClick={getData}
          value={state.searchTerm}
          onChange={(e) => setState({ ...state, searchTerm: e.target.value })}
        />
      </FormGroup>
    </div>
    {showDateRange && (
      <div className="col-lg-12">
        <div className="d-flex justify-content-start">
          <SingleDatePickerComponent
            setDate={(d) => setState({ ...state, fromDate: d.toDate() })}
            date={state.fromDate ? moment(state.fromDate) : null}
            id="fromDate"
            placeholder="Start Date"
            isBefore={false}
          />
          <SingleDatePickerComponent
            setDate={(d) => setState({ ...state, toDate: d.toDate() })}
            date={state.toDate ? moment(state.toDate) : null}
            id="toDate"
            placeholder="End Date"
            isBefore={false}
          />
          <div className="d-flex align-items-center justify-content-start pl-sm-3 pr-sm-3 mb-sm-1 mt-2">
            <button
              className="btn btn-primary pl-3 pr-3"
              disabled={state.currentPage === 1}
              onClick={() =>
                setState({ ...state, currentPage: state.currentPage - 1 })
              }
            >
              Prev
            </button>
            <p className="current-page-number ml-2 mr-2 mb-0">
              <strong>Page {state.currentPage}</strong>
            </p>
            <button
              className="btn btn-primary pl-3 pr-3"
              disabled={
                state.filteredData ? state.filteredData.length === 0 : true
              }
              onClick={() =>
                setState({ ...state, currentPage: state.currentPage + 1 })
              }
            >
              Next
            </button>
            <QButton
              loading={state.isExportDownloadLoading}
              onClick={handleExportOrders}
              round
              size="sm"
            >
              Download Orders
            </QButton>
          </div>
        </div>
      </div>
    )}
  </div>
)
export const TableTopFiltersNewUI = ({
  state,
  setState,
  getData,
  showDateRange = true,
  handleExportOrders,
  fieldsConfig = {
    showOrdertype: true,
    showPaymentMethod: true,
    showOrderStatus: true,

  }
}: any) => {

  const {
    showOrdertype,
    showPaymentMethod,
    showOrderStatus,

  } = fieldsConfig;
  return <div className="tr-filter-wrapper">


    <div className="tr-filter-row">
      {showOrdertype && <div className="tr-col one">
        <SelectComponent
          placeholder="Type"
          // value={state.orderType}
          onChange={(value) =>
            setState({ ...state, orderType: value })
          }
          options={[
            { value: '', label: 'ALL' },
            { value: "BUY", label: "Buy" },
            { value: "SELL", label: "Sell" }
          ]} />
      </div>}
      {showPaymentMethod && <div className="tr-col one">
        <SelectComponent
          placeholder="Method"
          onChange={(value) =>
            setState({
              ...state,
              paymentMethod: value,
            })
          }
          options={[
            { value: '', label: 'ALL' },
            ...Object.values(PaymentMethodEnum)
              .filter(
                (paymentMethod) =>
                  paymentMethod !== PaymentMethodEnum.None,
              )
              .map((method) => ({
                value: method,
                label: method,
              }))
          ]} />
      </div>}
      {showOrderStatus && <div className="tr-col one">
        <SelectComponent
          placeholder="Order Status"
          onChange={(value) =>
            setState({ ...state, orderStatus: value })
          }
          options={[
            { value: '', label: 'ALL' },
            ...Object.values(OrderStatus).map((status) => ({
              value: status,
              label: camelCaseToSpaced(status),
            }))
          ]} />
      </div>}
      <div className="tr-col two">
        <div className="row">
          <div className="col">
            <SelectComponentValue
              placeholder="Search By"
              value={state.searchType}
              onChange={(value: any) => {
                setState({ ...state, searchType: value as string })
              }}
              options={[
                { value: 'name', label: 'Search By - Name' },
                { value: 'email', label: 'Search By - Email' },
              ]} />
          </div>
          <div className="col">
            <div style={{
              position: "relative",
              zIndex: 0
            }}>

              <SearchInput
                onClick={getData}
                value={state.searchTerm}
                onChange={(e) => setState({ ...state, searchTerm: e.target.value })}
              />
            </div>
          </div>

        </div>

      </div>
      <div className="tr-col one ">
        <QButton
          fullWidth
          loading={state.isExportDownloadLoading}
          onClick={handleExportOrders}
          className='ml-auto d-block'
          size="sm"
        >
          Download Orders
        </QButton>
      </div>



    </div>
    <div className="tr-filter-row">
      {
        showDateRange &&
        <div className="tr-col two">
          <div className="double-datepicker">
            <SingleDatePickerComponent
              setDate={(d) => setState({ ...state, fromDate: d.toDate() })}
              date={state.fromDate ? moment(state.fromDate) : null}
              id="fromDate"
              placeholder="Start Date"
              isBefore={false}
            />
            <SingleDatePickerComponent
              setDate={(d) => setState({ ...state, toDate: d.toDate() })}
              date={state.toDate ? moment(state.toDate) : null}
              id="toDate"
              placeholder="End Date"
              isBefore={false}
            />
          </div>

        </div>
      }


      <div className="tr-col one">

        <div className="d-flex align-items-center justify-content-end  ml-auto">
          <button
            className="btn btn-primary pl-3 pr-3"
            disabled={state.currentPage === 1}
            onClick={() =>
              setState({ ...state, currentPage: state.currentPage - 1 })
            }
          >
            Prev
          </button>
          <p className="current-page-number ml-2 mr-2 mb-0">
            <strong>Page {state.currentPage}</strong>
          </p>
          <button
            className="btn btn-primary pl-3 pr-3"
            disabled={
              state.filteredData ? state.filteredData.length === 0 : true
            }
            onClick={() =>
              setState({ ...state, currentPage: state.currentPage + 1 })
            }
          >
            Next
          </button>

        </div>
      </div>



    </div>
  </div>


}
export const TableTopFiltersNewUIForBankPurchase = ({
  state,
  setState,
  getData,
  showDateRange = true,
  handleExportOrders,


}: any) => {



  return <div className="tr-filter-wrapper">


    <div className="tr-filter-row">
    <div className="tr-col two">
        <div className="double-datepicker">
          <SingleDatePickerComponent
            setDate={(d) => setState({ ...state, fromDate: d.toDate() })}
            date={state.fromDate ? moment(state.fromDate) : null}
            id="fromDate"
            placeholder="Start Date"
            isBefore={false}
          />
          <SingleDatePickerComponent
            setDate={(d) => setState({ ...state, toDate: d.toDate() })}
            date={state.toDate ? moment(state.toDate) : null}
            id="toDate"
            placeholder="End Date"
            isBefore={false}
          />
        </div>

      </div>
      <div className="tr-col one">
        <SelectComponent
          placeholder="Currency"
          onChange={(value) =>
            setState({ ...state, selectedCurrency: value })
          }
          options={[
            { value: '', label: 'ALL' },
            ...Object.values(BuyCurrencyEnum).filter(v => v !== "").map((key) => ({
              value: key,
              label: key,
            }))
          ]} />
      </div>

      <div className="tr-col two">
        <div className="row">
          <div className="col">
            <SelectComponentValue
              placeholder="Search By"
              value={state.searchType}
              onChange={(value: any) => {
                setState({ ...state, searchType: value as string })
              }}
              options={[
                { value: 'name', label: 'Search By - Name' },
                { value: 'email', label: 'Search By - Email' },
              ]} />
          </div>
          <div className="col">
            <div style={{
              position: "relative",
              zIndex: 0
            }}>

              <SearchInput
                onClick={getData}
                value={state.searchTerm}
                onChange={(e) => setState({ ...state, searchTerm: e.target.value })}
              />
            </div>
          </div>

        </div>

      </div>



      <div className="tr-col one">

        <div className="d-flex align-items-center justify-content-end  ml-auto">
          <button
            className="btn btn-primary pl-3 pr-3"
            disabled={state.currentPage === 1}
            onClick={() =>
              setState({ ...state, currentPage: state.currentPage - 1 })
            }
          >
            Prev
          </button>
          <p className="current-page-number ml-2 mr-2 mb-0">
            <strong>Page {state.currentPage}</strong>
          </p>
          <button
            className="btn btn-primary pl-3 pr-3"
            disabled={
              state.filteredData ? state.filteredData.length === 0 : true
            }
            onClick={() =>
              setState({ ...state, currentPage: state.currentPage + 1 })
            }
          >
            Next
          </button>

        </div>
      </div>

   
    </div>
    <div className="tr-filter-row">
      <div className="tr-col one ">
        <QButton
          fullWidth
          loading={state.isExportDownloadLoading}
          onClick={handleExportOrders}
          className='ml-auto d-block'
          size="sm"
        >
          Download Orders
        </QButton>
      </div>
    </div>

  </div>


}
export const TableTopFiltersNewUIForOtherPurchase = ({
  state,
  setState,
  getData,
  showDateRange = true,
  handleExportOrders,


}: any) => {



  return <div className="tr-filter-wrapper">


    <div className="tr-filter-row">
    <div className="tr-col two">
        <div className="double-datepicker">
          <SingleDatePickerComponent
            setDate={(d) => setState({ ...state, fromDate: d.toDate() })}
            date={state.fromDate ? moment(state.fromDate) : null}
            id="fromDate"
            placeholder="Start Date"
            isBefore={false}
          />
          <SingleDatePickerComponent
            setDate={(d) => setState({ ...state, toDate: d.toDate() })}
            date={state.toDate ? moment(state.toDate) : null}
            id="toDate"
            placeholder="End Date"
            isBefore={false}
          />
        </div>

      </div>
      <div className="tr-col one">
        <SelectComponent
          placeholder="Currency"
          onChange={(value) =>
            setState({ ...state, selectedCurrency: value })
          }
          options={[
            { value: '', label: 'ALL' },
            ...Object.values(BuyCurrencyEnum).filter(v => v !== "").map((key) => ({
              value: key,
              label: key,
            }))
          ]} />
      </div>

      <div className="tr-col two">
        <div className="row">
          <div className="col">
            <SelectComponentValue
              placeholder="Search By"
              value={state.searchType}
              onChange={(value: any) => {
                setState({ ...state, searchType: value as string })
              }}
              options={[
                { value: 'name', label: 'Search By - Name' },
                { value: 'email', label: 'Search By - Email' },
              ]} />
          </div>
          <div className="col">
            <div style={{
              position: "relative",
              zIndex: 0
            }}>

              <SearchInput
                onClick={getData}
                value={state.searchTerm}
                onChange={(e) => setState({ ...state, searchTerm: e.target.value })}
              />
            </div>
          </div>

        </div>

      </div>



      <div className="tr-col one">

        <div className="d-flex align-items-center justify-content-end  ml-auto">
          <button
            className="btn btn-primary pl-3 pr-3"
            disabled={state.currentPage === 1}
            onClick={() =>
              setState({ ...state, currentPage: state.currentPage - 1 })
            }
          >
            Prev
          </button>
          <p className="current-page-number ml-2 mr-2 mb-0">
            <strong>Page {state.currentPage}</strong>
          </p>
          <button
            className="btn btn-primary pl-3 pr-3"
            disabled={
              state.filteredData ? state.filteredData.length === 0 : true
            }
            onClick={() =>
              setState({ ...state, currentPage: state.currentPage + 1 })
            }
          >
            Next
          </button>

        </div>
      </div>

   
    </div>
    <div className="tr-filter-row">
      <div className="tr-col one ">
        <QButton
          fullWidth
          loading={state.isExportDownloadLoading}
          onClick={handleExportOrders}
          className='ml-auto d-block'
          size="sm"
        >
          Download Orders
        </QButton>
      </div>
    </div>

  </div>


}


interface BlogPostEditorProps {
  postId: string
}

export const BlogPostEditor: React.FC<BlogPostEditorProps> = ({ postId }) => {
  const [content, setContent] = useState<string>('test dafsdfas fasdfasd fasd')
  const [loading, setLoading] = useState<boolean>(false)
  const quillRef = useRef<ReactQuill | null>(null)

  useEffect(() => {
    // const fetchContent = async () => {
    //   try {
    //     const response = await axios.get(`/api/posts/${postId}`);
    //     setContent(response.data.content);
    //   } catch (error) {
    //     console.error('Error fetching blog post content:', error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    // fetchContent();
  }, [postId])

  const handleEditorChange = (value: string) => {
    setContent(value)
  }

  const handleSave = async () => {
    try {
      await axios.put(`/api/posts/${postId}`, { content })
      alert('Post saved successfully!')
    } catch (error) {
      console.error('Error saving blog post:', error)
      alert('Error saving post')
    }
  }

  const handleImageUpload = () => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      const file = input.files?.[0]
      if (file) {
        const formData = new FormData()
        formData.append('file', file)

        try {
          const response = await axios.post('/api/upload', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })

          const imageUrl = response.data.url
          const quill = quillRef.current?.getEditor()
          const range = quill?.getSelection()?.index

          if (range !== undefined && range >= 0) {
            quill?.insertEmbed(range, 'image', imageUrl)
          }
        } catch (error) {
          console.error('Error uploading image:', error)
          alert('Error uploading image')
        }
      }
    }
  }

  const modules = {
    toolbar: {
      container: [
        [{ header: [2, 3, 4, false] }],
        ['bold', 'italic', 'underline', 'blockquote'],
        [{ color: [] }],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        ['link', 'image'],
        ['clean'],
      ],
      handlers: {
        image: handleImageUpload,
      },
    },
    clipboard: {
      matchVisual: true,
    },
  }
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'clean',
  ]

  return (
    <div className="mt-5 pt-5">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <ReactQuill
            value={content}
            formats={formats}
            onChange={handleEditorChange}
            modules={modules}
            ref={(el) => (quillRef.current = el)}
            theme="snow"
          />
          <button onClick={handleSave}>Save</button>
        </>
      )}
    </div>
  )
}

export const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'color',
  'clean',
]
export const CustomReactQuillV3 = forwardRef<ReactQuill, any>((props, ref) => {
  const quillRef = useRef<ReactQuill>(null)
  const handleImageUpload = useCallback(() => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      const file = input.files?.[0]
      if (file) {
        const formData = new FormData()
        formData.append('image', file)

        try {
          const response = await addImage(formData)
          //@ts-ignore
          const imageUrl = response.data.fileUrl

          console.log('quill.current:', quillRef)
          // @ts-ignore
          const quill = quillRef.current?.getEditor()
          const range = quill?.getSelection()?.index

          if (range !== undefined && range >= 0) {
            quill?.insertEmbed(range, 'image', imageUrl)
          }
        } catch (error) {
          console.error('Error uploading image:', error)
          alert('Error uploading image')
        }
      }
    }
  }, [])
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ['bold', 'italic', 'underline', 'blockquote'],
          [{ color: [] }],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image', 'video'],
          ['clean'],
        ],
        handlers: {
          // image: imageHandler,
          image: handleImageUpload,
        },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    // [imageHandler],
    [handleImageUpload],
  )
  return (
    <ReactQuill
      style={{
        height: 500,
        marginBottom: 60,
      }}
      formats={formats}
      modules={modules}
      ref={quillRef}
      value={props.value}
      onChange={props.onChange}
      {...props}
    />
  )
})
export const HasToolTipLabel = ({
  id,
  txt,
  htmlFor,
  labelText,
}: {
  id: string
  txt: string
  htmlFor: string
  labelText: string
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [tooltipAction, setTooltipAction] = useState('')
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768)
  const menuTriggerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleDocumentClick = (event: any) => {
    // if (!event.target.closest(`#${stringToSlug(td.label)}`)) {
    //     setTooltipOpen(false);
    //     setTooltipAction("");
    // }

    if (
      event.target !== menuTriggerRef.current &&
      !menuTriggerRef.current?.contains(event.target as Node)
    ) {
      setTooltipOpen(false)
      setTooltipAction('')
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  const toggle = () => setTooltipOpen(!tooltipOpen)

  const handleMouseOver = () => {
    if (isDesktop) {
      setTooltipOpen(true)
    }
  }

  const handleMouseOut = () => {
    if (isDesktop && tooltipAction !== 'click') {
      setTooltipOpen(false)
    }
  }

  const handleClick = (e: React.MouseEvent) => {
    setTooltipAction('click')

    setTooltipOpen(true)
  }

  return (
    <span className="tooltip-label">
      <label className="font-weight-bold " htmlFor={htmlFor}>
        {labelText}
      </label>
      <span
        className="tooltip-info-icon"
        id={id}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        // onTouchStart={handleClick}
        ref={menuTriggerRef}
      >
        <i className="fa-solid fa-circle-info"></i>
      </span>
      <Tooltip
        placement="top"
        target={id}
        isOpen={tooltipOpen}
        // toggle={toggle} // do not remove this comment, with toggle hadleys feedback based ui does not work
        autohide={false}
        trigger="click"
      >
        {txt}
      </Tooltip>
    </span>
  )
}


export const TRInfo = ({ label, value}: { label: string, value: any }) => {
  return <div className='TRInfo'>
    <p className='label'>{label}</p>
    <p className='value'>{value}</p>
  </div>
}


interface Option {
  value: string;
  label: string;
}

interface SelectComponentProps {
  options: Option[];
  // value: string | null;
  onChange: (value: string | null) => void;
  placeholder?: string;
  isClearable?: boolean;
}
const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#f0151f' : '#fff',
    color: state.isSelected ? '#fff' : '#000',
    ':hover': {
      backgroundColor: '#f0151f',
      color: '#fff',
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? '#f0151f' : '#ccc',
    boxShadow: state.isFocused ? '0 0 0 1px #f0151f' : 'none',
    ':hover': {
      borderColor: state.isFocused ? '#f0151f' : '#ccc',
    },
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#f0151f',
    ':hover': {
      color: '#f0151f',
    },
  }),
};
const colourStyles: StylesConfig<any> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? "rgba(0,0,0,.1)"
            : undefined,
      color: '#ccc',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? "red"
            : "rgba(0,0,0,.1)"
          : undefined,
      },
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
  singleValue: (styles, { data }) => ({ ...styles, backgroundColor: "red" }),
};
export const SelectComponent: React.FC<SelectComponentProps> = ({
  options,
  // value,
  onChange,
  placeholder = 'Select an option',
  isClearable = true,
}) => {
  const handleChange = (selectedOption: Option | null) => {
    onChange(selectedOption ? selectedOption.value : null);
  };

  return (
    <Select
      styles={customStyles}
      className='SelectComponent'
      options={options}
      // value={options.find(option => option.value === value) || null}
      onChange={handleChange}
      placeholder={placeholder}
      // menuIsOpen={true}
      isClearable={isClearable}
    />
  );
};
export const SelectComponentValue: React.FC<{
  options: Option[];
  value: string | null;
  onChange: (value: string | null) => void;
  placeholder?: string;
  isClearable?: boolean;
}> = ({
  options,
  value,
  onChange,
  placeholder = 'Select an option',
  isClearable = false,
}) => {
    const handleChange = (selectedOption: Option | null) => {
      onChange(selectedOption ? selectedOption.value : null);
    };

    return (
      <Select
        styles={customStyles}
        className='SelectComponent'
        options={options}
        value={options.find(option => option.value === value) || null}
        onChange={handleChange}
        placeholder={placeholder}
        isClearable={isClearable}
      />
    );
  };
