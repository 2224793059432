import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import AdminDashboard from './pages/adminDasboard' // Corrected import
import AdminViewTransaction from './pages/AdminViewTransaction' // Corrected import
import Login from './pages/login'
import ResetPasswordRequestPage from './pages/ResetPasswordRequestPage'
import ResetPasswordPage from './pages/ResetPasswordPage'
import AddNewLand from './pages/addNewLand'
import EditLandDetails from './pages/editLandDetails'
import ViewLandDetails from './pages/viewAllLandDetails'
import DisableEnableUser from './pages/disableEnableUser'
import ViewUnitsBank from './pages/viewUsdInBank'
import AddQuestion from './pages/goverancePage/addQuestion'
import EditQuestion from './pages/goverancePage/editQuestion'
import ViewQuestion from './pages/goverancePage/viewQuestion'
import DeleteQuestion from './pages/goverancePage/deleteQuestion'
import ViewApproved from './pages/viewApproved/index'
import ApproveCommunityAgent from './pages/approveCommunityAgentFromList/index'
import EditUserDetails from './pages/editUserDetails/index'
import NotFound from './pages/notFoundPage'
import FeeManagement from './pages/setting/index'
import ApproveUserFromList from './pages/approveUserFromList/index'
import Userpurchase from './pages/userPurchase/index'
import Bankpurchase from './pages/bankPurchase/index'
import Usersellorder from './pages/userSellOrder/index'
import ApproveCompleted from './pages/UserPurchasesApprovedCompleted/index'
import AssignOrRemoveAdminRole from './pages/assignOrRemoveAdminRole'
import PayoutsPendingPurchase from './pages/PayoutsPendingPurchase'
import ReferenceCodeManagement from './pages/refereneceCode'
import ViewAllSuperAdmin from './pages/viewAllSuperAdmin'
import ViewSubscribers from './pages/viewSubscriber'

import './assets/scss/bootstrap-custom.scss'
import './assets/scss/QDesignSystem/scss/common.scss'

import './bootstrap5-utils.scss'
import 'react-toastify/dist/ReactToastify.css'

// plugins csss
import './assets/scss/dataTables.bootstrap4.css'
import './assets/scss/dropzone.css'

// theme css
import './assets/scss/admin.css'
// import "./assets/scss/bootstrap.min.css";
import './assets/scss/summernote-bs4.css'

import './assets/scss/vendors.css'

import './assets/scss/custom.css'
import './assets/scss/style.scss'
import './assets/scss/color-red.css'
import { ToastContainer } from 'react-toastify'
// import "./index.css";
import './new-overrides.scss'
import QDesignSystem from './pages/QDesignSystem'
import { getUserOrSuperAdmin } from './api'
import { AdminRoles, RouteType } from './utils/types'
import Header from './component/Sidbar'
import { QuillEditorComponent } from './component/Editor/main'
import Footer from './component/Footer'
import AddProjects from './pages/projectsPage/AddProjects'
import EditProjects from './pages/projectsPage/EditProjects'
import ViewProjects from './pages/projectsPage/ViewProjects'
import ViewCommunityIdeas from './pages/projectsPage/ViewCommunityIdeas'
import AddNews from './pages/addNews'
import DeleteUpdateViewNews from './pages/DeleteUpdateViewNews'
import { BlogPostEditor } from './component/misc/smallComponents'
import DelteUser from './pages/deleteUser'
import EditLandProjects from './pages/landProjectsPage/EditLandProject'
import AddLandProject from './pages/landProjectsPage/AddLandProject'
import ViewLandProjects from './pages/landProjectsPage/ViewLandProjects'

import EditNewsCategory from './pages/newsCategory/EditNewsCategory'
import AddNewsCategory from './pages/newsCategory/AddNewsCategory'
import ViewNewsCategory from './pages/newsCategory/ViewNewsCategory'
import ViewApprovedCommunityAgent from './pages/viewApprovedCommunityAgent'
// import Userpurchse from './pages/userPurchase/index'

function App() {
  const [authUserOrAdmin, setAuthUserOrAdmin] = useState<string | null>(null) // Corrected type
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false) // Corrected type
  const [loading, setLoading] = useState(true)
  const [userRoles, setUserRoles] = useState<AdminRoles[]>([])

  useEffect(() => {
    const getSuperAdmin = () => {
      getUserOrSuperAdmin()
        .then((res) => {
          const user = res.data.user
          const isSuperAdmin = user.isSuperAdmin ? true : false
          setIsSuperAdmin(isSuperAdmin)
          const userRoles = user.roles ? user.roles : []
          setUserRoles(userRoles)
          setAuthUserOrAdmin(res.data.user)
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    }
    getSuperAdmin()
  }, [])

  const renderRoute = ({ path, element, requiredRoles = [] }: RouteType) => {
    if (
      isSuperAdmin ||
      (requiredRoles.length > 0 &&
        requiredRoles.some((role) => userRoles.includes(role))) ||
      (requiredRoles.length === 0 && (isSuperAdmin || userRoles.length > 0))
    ) {
      return <Route path={path} element={element} />
    }
    if (path === '/' && userRoles.length === 0)
      return <Route path="/" element={<Login />} />

    return null
  }

  return loading ? (
    <p>Loading please wait....</p>
  ) : (
    <BrowserRouter>
      <ToastContainer />
      <Header />
      <Routes>
        {authUserOrAdmin ? (
          <>
            {renderRoute({ path: '/', element: <AdminDashboard /> })}
            {renderRoute({
              path: '/approveUserFromList',
              element: <ApproveUserFromList />,
              requiredRoles: [AdminRoles.UserAdmin],
            })}
            {renderRoute({
              path: '/approve-community-agent',
              element: <ApproveCommunityAgent />,
              requiredRoles: [AdminRoles.UserAdmin],
            })}
            {renderRoute({
              path: '/view-approved-agent',
              element: <ViewApprovedCommunityAgent />,
              requiredRoles: [AdminRoles.UserAdmin],
            })}
            {renderRoute({
              path: '/viewApproved',
              element: <ViewApproved />,
              requiredRoles: [AdminRoles.UserAdmin],
            })}
            {renderRoute({
              path: '/view-subscribers',
              element: <ViewSubscribers />,
              requiredRoles: [AdminRoles.UserAdmin],
            })}
            {renderRoute({
              path: '/viewSuperAdmin',
              element: <ViewAllSuperAdmin />,
              requiredRoles: [AdminRoles.SuperAdmin],
            })}
            {renderRoute({
              path: '/editUserDetails',
              element: <EditUserDetails />,
              requiredRoles: [AdminRoles.SuperAdmin],
            })}
            {renderRoute({
              path: '/deleteUser',
              element: <DelteUser />,
              requiredRoles: [AdminRoles.SuperAdmin],
            })}
            {renderRoute({
              path: '/enableUser',
              element: <DisableEnableUser />,
              requiredRoles: [AdminRoles.UserAdmin],
            })}
            {renderRoute({
              path: '/assignremoveadminrole',
              element: <AssignOrRemoveAdminRole />,
              requiredRoles: [AdminRoles.SuperAdmin],
            })}
            {renderRoute({
              path: '/addNewLand',
              element: <AddNewLand />,
              requiredRoles: [AdminRoles.LandAdmin],
            })}

            {renderRoute({
              path: '/viewLand',
              element: <ViewLandDetails />,
              requiredRoles: [AdminRoles.LandAdmin],
            })}
            {renderRoute({
              path: '/editLand',
              element: <EditLandDetails />,
              requiredRoles: [AdminRoles.SuperAdmin],
            })}
            {renderRoute({
              path: '/viewAudInBank',
              element: <ViewUnitsBank />,
              requiredRoles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
            })}
            {renderRoute({
              path: '/payoutuserpurchase',
              element: <PayoutsPendingPurchase />,
              requiredRoles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
            })}
            {renderRoute({
              path: '/bankuserpurchase',
              element: <Bankpurchase />,
              requiredRoles: [AdminRoles.BankAdmin],
            })}
            {renderRoute({
              path: '/otherpurchase',
              element: <Userpurchase />,
              requiredRoles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
            })}

            {renderRoute({
              path: '/usersellorder',
              element: <Usersellorder />,
              requiredRoles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
            })}
            {renderRoute({
              path: '/approvecompleted',
              element: <ApproveCompleted />,
              requiredRoles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
            })}
            {renderRoute({
              path: '/addQuestion',
              element: <AddQuestion />,
              requiredRoles: [AdminRoles.VotingAdmin],
            })}
            {renderRoute({
              path: '/viewQuestion',
              element: <ViewQuestion />,
              requiredRoles: [AdminRoles.VotingAdmin],
            })}
            {renderRoute({
              path: '/addProject',
              element: <AddProjects />,
              requiredRoles: [AdminRoles.VotingAdmin],
            })}
            {renderRoute({
              path: '/editProject/:projectID',
              element: <EditProjects />,
              requiredRoles: [AdminRoles.VotingAdmin],
            })}
            {renderRoute({
              path: '/viewProjects/',
              element: <ViewProjects />,
              requiredRoles: [AdminRoles.VotingAdmin],
            })}

            {renderRoute({
              path: '/addLandProject',
              element: <AddLandProject />,
              requiredRoles: [AdminRoles.VotingAdmin],
            })}
            {renderRoute({
              path: '/editLandProject/:id',
              element: <EditLandProjects />,
              requiredRoles: [AdminRoles.VotingAdmin],
            })}
            {renderRoute({
              path: '/viewLandProjects/',
              element: <ViewLandProjects />,
              requiredRoles: [AdminRoles.VotingAdmin],
            })}

            {renderRoute({
              path: '/community-ideas/',
              element: <ViewCommunityIdeas />,
              requiredRoles: [AdminRoles.VotingAdmin],
            })}
            {renderRoute({
              path: '/editQuestion/:voteTopicID',
              element: <EditQuestion />,
              requiredRoles: [AdminRoles.VotingAdmin],
            })}
            {renderRoute({
              path: '/deleteQuestion',
              element: <DeleteQuestion />,
              requiredRoles: [AdminRoles.VotingAdmin],
            })}

            {renderRoute({
              path: '/setting/feeManagement',
              element: <FeeManagement />,
              requiredRoles: [AdminRoles.SuperAdmin],
            })}

            {renderRoute({
              path: '/setting/reference-code-management',
              element: <ReferenceCodeManagement />,
              requiredRoles: [AdminRoles.SuperAdmin],
            })}
            {renderRoute({
              path: '/news/create',
              element: <AddNews />,
              requiredRoles: [AdminRoles.NewsAdmin],
            })}

            {renderRoute({
              path: '/news/operation',
              element: <DeleteUpdateViewNews />,
              requiredRoles: [AdminRoles.NewsAdmin],
            })}

            {renderRoute({
              path: '/news/addNewsCategory',
              element: <AddNewsCategory />,
              requiredRoles: [AdminRoles.NewsAdmin],
            })}
            {renderRoute({
              path: '/news/editNewsCategory/:id',
              element: <EditNewsCategory />,
              requiredRoles: [AdminRoles.NewsAdmin],
            })}
            {renderRoute({
              path: '/news/viewNewsCategory/',
              element: <ViewNewsCategory />,
              requiredRoles: [AdminRoles.NewsAdmin],
            })}

            {renderRoute({
              path: '/admin-view-transaction',
              element: <AdminViewTransaction />,
              requiredRoles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
            })}
          </>
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route
              path="/login/forgot/"
              element={<ResetPasswordRequestPage />}
            />

            <Route path="/login/reset/:token" element={<ResetPasswordPage />} />
          </>
        )}
        <Route path="/design/" element={<QDesignSystem />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App
